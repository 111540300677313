<template>
  <reset-password />
</template>

<script>
import resetPassword from '../../components/User/Auth/resetPassword/resetPassword.vue'
export default {
  components: { resetPassword },

}
</script>

<style>

</style>